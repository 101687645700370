import ClientOnly from '@/components/ClientOnly';
import type { CmsEventTeaser, CmsPage } from '@/components/CmsInterfaces';
import Footer from '@/components/Footer';
import Header from '@/components/Header';
import HomeGameTeaser from '@/components/HomeGameTeaser';
import { Layout, LayoutVariant } from '@/components/Layout';
import LinkWrapper from '@/components/LinkWrapper';
import NewsTeaser from '@/components/NewsTeaser';
import Page from '@/components/Page';
import WinningNumbersTeaser from '@/components/WinningNumbersTeaser';
import { eurojackpot } from '@/config/game/eurojackpot';
import { lotto6aus49 } from '@/config/game/lotto6aus49';
import requestEventTeaserCollection from '@/contentful/requestEventTeaserCollection';
import MarketingParams from '@/helpers/MarketingParams';
import { getNextJackpot } from '@/helpers/getNextJackpot';
import classnames from 'classnames';

interface PageHomeProps extends CmsPage, CmsEventTeaser {
  title: string;
}

interface PageHomeState {
  jackpotData: any;
}

export default class PageHome extends Page<PageHomeProps, PageHomeState> {
  async componentDidMount() {
    const data = {
      [lotto6aus49.id]: await getNextJackpot(lotto6aus49),
      [eurojackpot.id]: await getNextJackpot(eurojackpot),
    };
    this.setState({ jackpotData: data });
  }

  public render(): JSX.Element {
    const { path, eventTeaser } = this.props;

    return (
      <>
        <Header />
        <main className="page page--start">
          <ClientOnly>
            {MarketingParams.isCashback() && (
              <div>
                <div
                  className={classnames(
                    'Cashback',
                    `Cashback--${MarketingParams.getNetwork()}`
                  )}
                >
                  <div className={classnames('Cashback__body')}>
                    <div>
                      <span>
                        Ihre Weiterleitung zu LOTTO.de war erfolgreich.&nbsp;
                        <br />
                        <b>{MarketingParams.getRewardCurrency()}</b> sammeln Sie
                        nach Abgabe des Spielauftrags.
                      </span>
                      <img
                        src={MarketingParams.getNetworkLogo('header')}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </ClientOnly>
          <Layout path={path} variant={LayoutVariant.Home} theme="home">
            <section className="Section Section--no-border">
              <div className="Section__content">
                <HomeGameTeaser
                  eventTeaser={eventTeaser}
                  jackpotData={this.state?.jackpotData}
                />
              </div>
            </section>
            <section className="Section Section--no-border Section--no-margin Section__headline">
              <h1 className="Section__headline">
                LOTTO.de – Ihre Informationsplattform
              </h1>
            </section>
            <section className="Section Section--no-border">
              <h2 className="Section__headline headline">
                Die aktuellen Gewinnzahlen
              </h2>
              <div className="Section__content">
                <WinningNumbersTeaser />
              </div>
            </section>
            <section
              id="section-neuigkeiten"
              className="Section Section--no-border"
            >
              <h2 className="Section__headline headline">
                Neuigkeiten
                <LinkWrapper
                  href="/ueber/neuigkeiten"
                  className="Section__link"
                  tabIndex={-1}
                >
                  <button
                    type="button"
                    className="Button Button--primary"
                    tabIndex={0}
                  >
                    <span className="h-flex h-flex--center-all">
                      Alle Neuigkeiten
                    </span>
                  </button>
                </LinkWrapper>
              </h2>
              <div className="Section__content">
                <NewsTeaser amount="3" category="alle" />
              </div>
              <footer className="Section__footer">
                <LinkWrapper
                  href="/ueber/neuigkeiten"
                  className="Section__link"
                  tabIndex={-1}
                >
                  <button
                    type="button"
                    className="Button Button--primary"
                    tabIndex={0}
                  >
                    <span className="h-flex h-flex--center-all">
                      Alle Neuigkeiten
                    </span>
                  </button>
                </LinkWrapper>
              </footer>
            </section>
          </Layout>
          <Footer />
        </main>
      </>
    );
  }
}

export async function getStaticProps() {
  const items = (await requestEventTeaserCollection({}))[
    'eventTeaserCollection'
  ]['items'];
  const eventTeaser = items.length === 1 ? items[0] : null;
  return {
    props: {
      path: '/',
      title: 'LOTTO.de - Spielen beim Original!',
      description:
        'LOTTO.de ist die seriöse und sichere Informationsplattform für die Lotterien LOTTO 6aus49, Eurojackpot, GlücksSpirale, KENO und Rubbellose',
      theme: 'default',
      eventTeaser: eventTeaser
        ? {
            title: eventTeaser.title,
            link: eventTeaser.link,
            imageDesktop: eventTeaser.imageDesktop.url,
            imageMobile: eventTeaser.imageMobile.url,
          }
        : null,
    },
  };
}
